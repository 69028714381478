<template>
  <div>
    <img
      v-if="getCountryFlagUrl(countryFlag)"
      id="flag"
      :src="getCountryFlagUrl(countryFlag)"
      style="border-radius: 6px; box-shadow: 0 20px 50px -20px"
      :style="'display: relative; width:' + (countryFlagSize ? countryFlagSize : 27) + 'px'"
      @error="$event.target.src = '/flags/noflag.svg'"
    />
    <i v-else class="pi pi-spin pi-spinner" style="font-size: 16px; margin-top: 5px"></i>
  </div>
</template>
<script src="./Flag.ts"></script>
